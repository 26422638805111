<template>
  <div>
    <div v-if="accountRegions.length > 0">
      <div class="mx-auto container">
        <region-navigation :region="currentRegion" class="mb-4" />
      </div>

      <div class="w-full sticky bg-gray-100 top-0 z-10">
        <div class="mx-auto container">
          <header v-if="currentRegion === 'combined'" class="mb-5 py-5 border-b border-gray-200">
            <div class="text-gray-600 mb-6">
              <span class="text-lg mr-4">{{ regionListHeading }}</span>
              <label class="text-sm">
                <input type="checkbox" @click="toggleAllSelectedRegions" class="cursor-pointer text-base leading-none pt mr-1">
                All
              </label>
            </div>
            <div class="mb-2 flex flex-col md:flex-row items-top justify-between">
              <div class="text-gray-600 text-center md:text-left mb-4 md:mb-0">
                <ul class="flex flex-wrap items-center">
                  <li
                    v-for="region in unrestrictedRegions"
                    :key="region.id"
                    @click="toggleSelectedRegion(region.id)"
                    class="mr-4 mb-3 px-3 py-2 border rounded-lg cursor-pointer"
                    :class="selectedRegions.includes(region.id) ? 'bg-accent border-accent text-white' : 'bg-white text-gray-500'"
                  >{{ region.postcode }}</li>
                </ul>
              </div>

              <type-filter v-if="! restrictedRegion && ! isCampaignPage" v-model="propertyFilter" />
            </div>
          </header>
          <header v-else class="flex flex-col md:flex-row items-center justify-between mb-5 py-5 border-b border-gray-200">
            <div class="text-gray-600 text-center md:text-left mb-4 md:mb-0">
              <span class="text-lg">Market Analysis for:</span> <br>
              <span class="text-accent text-3xl">{{ currentRegion.postcode }}...</span>
            </div>

            <type-filter v-if="! restrictedRegion && ! isCampaignPage" v-model="propertyFilter" />
          </header>
        </div>
      </div>

      <div v-if="currentRegion === 'combined' && selectedRegions.length === 0" class="mx-auto container mt-8">
        <div class="flex w-full items-center px-6 py-4 bg-white border border-accent rounded text-accent font-bold">
          <icon name="attention" class="w-8 h-8 mr-3" />
          <p>Please select regions from the list above</p>
        </div>
      </div>
      <div v-else class="mx-auto container">
        <div v-if="! restrictedSection">
          <router-view />
        </div>
        <div v-else>
          <restricted-region />
        </div>
      </div>
    </div>
    <div v-else class="mx-auto container">
      <div class="flex w-full items-center px-6 py-4 bg-white border border-accent rounded text-accent font-bold">
        <icon name="attention" class="w-8 h-8 mr-3" />
        <p>Please add <router-link :to="{ name: 'manage-postcodes' }" class="underline">postcode regions</router-link> for related market analysis</p>
      </div>
    </div>
  </div>
</template>

<script>
import RegionNavigation from '@/components/RegionNavigation'
import RestrictedRegion from '@/components/RestrictedRegion'
import TypeFilter from '@/components/TypeFilter'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'Region',
  components: {
    RegionNavigation,
    TypeFilter,
    RestrictedRegion
  },

  created () {
    this.initialiseSelectedRegion()
  },

  computed: {
    ...mapState(['propertyTypeFilter', 'accountRegions', 'selectedRegions']),
    ...mapGetters(['currentRegion', 'currentPage']),

    isCampaignPage() {
      return this.$route.path.indexOf('automated-marketing') > -1;
    },

    propertyFilter: {
      get () {
        return this.propertyTypeFilter
      },

      set (val) {
        this.updatePropertyTypeFilter(val)
      }
    },

    restrictedRegion() {
      return this.currentRegion.restricted
    },

    restrictedSection() {
      return this.currentRegion.restricted
        && this.currentPage !== "region:automated-marketing"
    },

    unrestrictedRegions() {
      return this.accountRegions.filter(region => !region.restricted)
    },

    regionListHeading() {
      switch (this.currentPage) {
        case 'region:figures-and-charts':
          return 'Display Stats for these areas:';
        case 'region:income-chart':
          return 'Display Potential Income for these areas:';
        case 'region:street-summary':
          return 'Display Streets in these areas:';
        case 'region:locality-summary':
          return 'Display Localities in these areas:';
        case 'region:automated-marketing':
          return 'Campaigns including these areas:';
        case 'region:area-covered':
          return 'Display Areas:';
        default:
          return 'Market Analysis for:';
      }
    }
  },

  methods: {
    ...mapActions(['updatePropertyTypeFilter', 'setSelectedRegion', 'toggleSelectedRegion', 'toggleAllSelectedRegions']),

    initialiseSelectedRegion() {
      if (this.currentRegion === 'combined') {
        if (this.unrestrictedRegions.length > 0) {
          this.setSelectedRegion(this.unrestrictedRegions[0].id)
        } else {
          this.setSelectedRegion()
        }
      } else {
        this.setSelectedRegion(this.currentRegion.id)
      }
    }
  },

  watch: {
    currentRegion () {
      this.initialiseSelectedRegion()
    }
  }
}
</script>
