<template>
  <div class="pb-6">
    <modal v-if="showTemplates" @close="showTemplates = false">
      <marketing-templates v-model="selectedCampaign.templateId" category="Fee Street" :genre="null" @input="showTemplates = false; updateSelectedCampaign();"/>
    </modal>
    <modal v-if="showDecreaseTemplates" @close="showDecreaseTemplates = false">
      <marketing-templates v-model="selectedCampaign.priceDecreaseTemplateId" category="Fee Street Decrease" :genre="null" @input="showDecreaseTemplates = false; updateSelectedCampaign()"/>
    </modal>
    <modal-small class="w-1/2 h-1/2" v-if="showOverPrice" @close="showOverPrice = false">
      <div class="p-4 w-full">
        <div class="flex">
          <icon name="attention" class="w-10 h-10 mr-4" />
          <h2 class="text-2xl font-bold mb-4">Attention!</h2>
        </div>
        <p class="text-left mb-4">It appears you're trying to activate a campaign that's over your spend limit. Please follow the link below to fill out the form, to request your spend limit to be increased.</p>
        <div class="mb-4 bg-gray-200 p-2 rounded">
          <p><strong>Current Spend Limit:</strong> {{ moneyFormat(profile.office.spend_limit.limit) }}</p>
          <p><strong>Campaign Cost:</strong> {{ moneyFormat(pricePerCard * (propertyCountTotal+seedCardCountTotal)) }}</p>
        </div>
        <div class="w-full flex items-center justify-center">
          <a class="block p-2 rounded text-white bg-accent hover:bg-accent" href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e5db8411-28f2-4972-9d03-c7bfb00ca167&env=na3&acct=87f0312d-cc6d-4581-8346-2312d857bb1f&v=2" target="_blank" rel="noopener noreferrer">Sign</a>
        </div>
      </div>
    </modal-small>
    <modal-small v-if="showDeleteConfirmFlag" @close="showDeleteConfirmFlag = false">
      <div class="flex p-8 w-full flex-col items-center">
        <div class="text-center mb-2 flex">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          <span class="text-xl font-bold uppercase">Delete Campaign</span>
        </div>
        <div class="mb-4 flex text-center">
          Are you sure you want to delete this campaign and all its associated filters?
        </div>
        <div class="w-full flex justify-center">
          <button class="mr-2 btn bg-gray-600 text-white" @click="confirmDelete">Yes, delete it</button>
          <button class="ml-2 btn" @click="showDeleteConfirmFlag = false">No, go back</button>
        </div>
      </div>
    </modal-small>
    <modal-small v-if="showCampaignActivateDialogue" @close="showCampaignActivateDialogue = false" bg-class="bg-gray-700">
      <div class="flex p-8 w-full flex-col items-center text-white bg-gray-700">
        <div class="text-center mb-2 flex">
          <icon name="attention" class="h-16"></icon>
        </div>
        <div v-if="ActvivateDialogueFlags.GivenActiveFlag">
          <div v-if="ActvivateDialogueFlags.ImmediatelyPrintFlag">
            You are about to activate this campaign. This means an order will be generated and sent to print immediately for this campaign. Are you sure?
            <p class="mt-4" v-if="campaignLocations.length < campaignLocationsTotalCount">
              One or more areas you have selected is present in multiple campaigns. If these campaigns are active, then the properties in this area will receive multiple marketing materials. To prevent this either delete or de-active the campaigns with duplicate locations in them or remove the duplicated area from the affected campaigns.
            </p>
          </div>
          <div v-else>
            <div v-if="!ActvivateDialogueFlags.ignoreNextPrintDate">
              You are about to activate this campaign. It last printed on {{ dateFormat(selectedCampaign.lastOrderTimestamp, 'dd/MM/yyyy') }} and is set to print {{overallCostText}}, so will next print on {{ dateFormat(selectedCampaign.nextOrderTimestamp, 'dd/MM/yyyy') }}. Are you sure?
            </div>
            <div v-else>
              You are about to activate this campaign and ignore the last print date. It last printed on {{ dateFormat(selectedCampaign.lastOrderTimestamp, 'dd/MM/yyyy') }}. An order will be generated and sent to print immediately.
            </div>
            <input type="checkbox" v-model="ActvivateDialogueFlags.ignoreNextPrintDate" class="cursor-pointer text-base leading-none my-1 mr-2"> <span>Ignore the last print date, and print this Campaign immediately</span>
          </div>
          <div class="w-full flex justify-center mt-6">
            <button class="mr-2 btn bg-green-600 text-white" @click="selectedCampaign.active=true; updateSelectedCampaign(); showCampaignActivateDialogue = false;">Yes, Activate it</button>
            <button class="ml-2 btn bg-gray-600 text-white" @click="showCampaignActivateDialogue = false">No, don't order it</button>
          </div>
        </div>
        <div v-else>
          <div class="mb-4 flex text-center">
            You are about to deactivate this campaign. This means the next scheduled run for this campaign will not go to print. Are you sure?
          </div>
          <div class="w-full flex justify-center mt-6">
            <button class="mr-2 btn bg-black text-white" @click="selectedCampaign.active=false; updateSelectedCampaign(); showCampaignActivateDialogue = false;">Yes, cancel it</button>
            <button class="ml-2 btn bg-gray-600 text-white" @click="showCampaignActivateDialogue = false">No, keep it</button>
          </div>
        </div>
      </div>
    </modal-small>
    <modal-small class="w-1/2 h-1/2" v-if="showTextInfo" @close="showTextInfo = false">
      <div class="p-4 w-full">
        <p class="text-left mb-4">{{ textInfo }}</p>
      </div>
    </modal-small>
    <campaign-picker
      @newCampaign="createNewCampaign"
      @campaignUpdated="updateCampaign"
      :campaigns="campaigns"
      :isManagementOffice="isManagementOffice"
      v-model="selectedCampaign"
    />
    <div class="relative flex items-center justify-center p-6" v-if="!selectedCampaign">
      <loader class="relative" />
    </div>
    <panel v-else class="flex flex-col md:flex-row mb-10 rounded-t-none">
      <div class="w-full md:h-full flex justify-around md:flex-col md:mr-5">
        <div class="flex w-full">
          <div class="flex flex-col w-full">
            <div class="flex items-center mb-8">
              <h2 class="text-lg mr-5">{{ selectedCampaign.name }}</h2>
              <loader-container :loading="updatingCampaignName">
                <input
                  v-if="editCampaignName"
                  v-model="selectedCampaign.name"
                  @blur="updateCampaignName(selectedCampaign, $event.target.value)"
                  @keyup.enter="$event.target.blur()"
                  :id="`campaign_name_${selectedCampaign.id}`"
                  class="w-48 mr-2 bg-transparent border-b border-gray-500 focus:border-accent focus:outline-none focus:text-accent"
                />
                <span v-else @click="openRenameCampaign" class="text-gray-500 flex items-center cursor-pointer">
                  <icon name="edit" class="w-6 h-6 mr-2" />
                  Rename Campaign
                </span>
              </loader-container>
              <span v-if="campaigns.length>1" class="ml-5 text-gray-500 flex items-center cursor-pointer" @click="showDeleteConfirmFlag=true">
                <svg  xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                Delete Campaign
              </span>
            </div>
            <div class="flex" v-if="canActivateCampaign">
              <div v-if="!selectedCampaignIsAnniversaryCard">
                <div class="flex">
                  <div>
                    <template-preview :productId="selectedCampaign.templateId || 0" @show-templates-modal="showTemplates = true" label="Price Increase" tooltip="Select the template to mail out when a value increase occurs." />
                  </div>
                  <div :style="selectedCampaign.priceDecreaseTemplateId && !selectedCampaign.sendOnPriceDecrease? 'opacity:20%':''">
                    <template-preview :productId="selectedCampaign.priceDecreaseTemplateId || 0" @show-templates-modal="showDecreaseTemplates = true"  category="Fee Street"
                                      label="Price Decrease" tooltip="Select the template to mail out when a value decrease occurs. You can stop sending out mail when the value decreases by disabling this template." />
                  </div>
                </div>
                <div class="w-full mt-6" v-if="selectedCampaign.priceDecreaseTemplateId">
                  <input type="checkbox" v-model="selectedCampaign.sendOnPriceDecrease" @change="updateSelectedCampaign" class="cursor-pointer text-base leading-none my-1 mr-1" id="sendOnPriceDecrease"> <label for="sendOnPriceDecrease">Send 'Price Decrease' message</label>
                </div>
              </div>
              <div class="w-full" v-else-if="isManagementOffice">
                <div class="flex flex-wrap" v-if="selectedCampaign.anniversaryItems.length>0">
                  <div class="w-1/2" v-for="(anniversaryItem, index) in selectedCampaign.anniversaryItems" :key="index">
                    <template-preview :productId="anniversaryItem.template_id || 0" @show-templates-modal="showAnniversaryTemplates = index"
                                      :label="anniversaryTypes[anniversaryItem.type_id]" category="Anniversary" />
                    <modal v-if="showAnniversaryTemplates === index" @close="showAnniversaryTemplates = false">
                      <marketing-templates v-model="anniversaryItem.template_id" category="Anniversary" :genre="anniversaryItem.type_id ==1 ? '1 Year' : 'All Years'" @input="showAnniversaryTemplates = false; updateSelectedCampaign()"/>
                    </modal>
                  </div>
                </div>
              </div>
              <loader-container :loading="updatingCampaign" class="flex flex-col flex-grow mr-6">
                <div class="flex mb-6">
                  <form-group class="mr-6 relative">
                    <label class="uppercase text-sm text-gray-500">Activate Campaign:</label>
                    <div class="w-36 h-12 rounded-full text-white relative cursor-pointer" :class="selectedCampaign.active ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-300 hover:bg-gray-500'"  @click="changeCampaignStatus()">
                      <div style="transition: all 250ms ease-in-out" :class="selectedCampaign.active ? 'w-10 h-10 absolute left-1 bg-white rounded-full top-1' : 'w-10 h-10 absolute right-1 bg-white rounded-full top-1'"></div>
                      <div :class="selectedCampaign.active ? 'mr-6 mt-3 float-right' : 'ml-4 mt-3'">
                        {{ selectedCampaign.active ? 'ACTIVE' : 'INACTIVE'}}
                      </div>
                    </div>
                  </form-group>

                  <form-group class="w-1/2" v-if="!selectedCampaignIsAnniversaryCard">
                    <label class="uppercase text-sm text-gray-500">Frequency:</label>
                    <select-wrapper >
                      <select @change="updateSelectedCampaign" v-model="selectedCampaign.frequency" class="outline-none">
                        <option :value="1">Annually</option>
                        <option :value="2">Quarterly</option>
                        <option :value="3">Bi-monthly</option>
                        <option :value="4">Monthly</option>
                      </select>
                    </select-wrapper>
                  </form-group>
                  <form-group class="w-1/2" v-else-if="isManagementOffice">
                    <label class="uppercase text-sm text-gray-500">Anniversaries:</label>
                    <div class="flex w-full flex-wrap items-center">
                      <div class="w-1/2 my-1 pr-2" v-for="(anniversaryType,index) in anniversaryTypes" :key="index">
                        <input type="checkbox" class="cursor-pointer text-base leading-none mr-2" @change="saveAnniversaryItem(index, $event.target.checked)" :checked="checkType(index)" >
                        <span>{{anniversaryType}}</span>
                      </div>
                    </div>
                  </form-group>
                </div>

                <div class="w-full">
                  <form-group v-if="districtRegions" class="mr-6 mb-2">
                    <label class="uppercase text-sm text-gray-500">Districts:</label>
                    <div class="flex flex-wrap w-full">
                      <div v-for="district in districtRegions" :key="district.id" class="mb-2 w-1/2 md:w-1/4">
                        <input type="checkbox" @change="changeCampaignRegion(district.id)" :checked="selectedDistricts.includes(district.id)" :id="'district-' + district.id" class="cursor-pointer text-base leading-none my-1 mr-2"> <label :for="'district-' + district.id">{{ district.postcode }}</label>
                      </div>
                    </div>
                  </form-group>
                  <form-group v-if="sectorRegions" class="mr-6">
                    <label class="uppercase text-sm text-gray-500">Sectors:</label>
                    <div class="flex flex-wrap w-full">
                      <div v-for="sector in sectorRegions" :key="sector.id" class="mb-2 w-1/2 md:w-1/4">
                        <input type="checkbox" @change="changeCampaignRegion(sector.id)" :checked="selectedSectors.includes(sector.id)" :id="'sector-' + sector.id" class="cursor-pointer text-base leading-none my-1 mr-2"> <label :for="'sector-' + sector.id">{{ sector.postcode }}</label>
                      </div>
                    </div>
                  </form-group>
                </div>
                <hr class="mt-2 mb-4">

                <div class="flex mb-2" v-if="!selectedCampaignIsAnniversaryCard">
                  <form-group class="mr-6">
                    <label class="uppercase text-sm text-gray-500">Target Council Tax Bands:</label>
                    <div class="flex">
                      <select-wrapper class="mr-4">
                        <select @change="updateSelectedCampaign(); getPropertyCounts()" v-model="selectedCampaign.councilTaxFilter.from" class="outline-none">
                          <option v-for="option in cTaxFromOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                      </select-wrapper>

                      <select-wrapper>
                        <select @change="updateSelectedCampaign(); getPropertyCounts()" v-model="selectedCampaign.councilTaxFilter.to" class="outline-none">
                          <option v-for="option in cTaxToOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                      </select-wrapper>
                    </div>
                  </form-group>

                  <div v-if="! includeAllCtb" class="flex flex-col">
                    <span class="uppercase text-sm text-gray-500 mb-3">Include Unknown CTB
                      <button class="text-accent" @click="showUnknownCtbInfo = true"><icon name="information" class="cursor-pointer inline leading-none w-4 h-4" /></button>:</span>
                    <div class="flex">
                      <input type="checkbox" @change="updateSelectedCampaign(); getPropertyCounts()" v-model="selectedCampaign.councilTaxFilter.includeUnknown" class="cursor-pointer text-base leading-none my-1 mr-2"> <span v-if="unknownCtbPropertyCount && unknownCtbPropertyPercent">{{ unknownCtbPropertyPercent }}% ({{ unknownCtbPropertyCount }} properties)</span>
                      <modal-small class="w-1/2 h-1/2" v-if="showUnknownCtbInfo" @close="showUnknownCtbInfo = false">
                        <div class="p-4 w-full">
                          <p class="text-left mb-4">Tick to include properties with an unknown Council Tax band in this campaign.</p>
                          <p v-if="unknownCtbPropertyCount && unknownCtbPropertyPercent" class="text-left mb-4">The current campaign locations have a total of <strong>{{ unknownCtbPropertyCount }}</strong> properties (<strong>{{ unknownCtbPropertyPercent }}%</strong>) with an unknown Council Tax band.</p>
                        </div>
                      </modal-small>
                    </div>
                  </div>
                </div>
                <div class="flex mb-2" v-else-if="isManagementOffice">
                  <form-group class="mr-6">
                    <label class="uppercase text-sm text-gray-500">Min Property Price:
                      <button class="text-accent" @click="showInfoWithText('Property prices are based on the estimated current value, not the most recent transaction price.')">
                        <icon name="information" class="cursor-pointer inline leading-none w-4 h-4" />
                      </button>
                    </label>
                    <div class="flex">
                      <select-wrapper class="mr-4">
                        <select @change="updateSelectedCampaign()" v-model="selectedCampaign.priceFilter.from" class="outline-none">
                          <option :value="null">No minimum</option>
                          <option v-for="option in priceOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                      </select-wrapper>
                    </div>
                  </form-group>
                  <form-group class="mr-6">
                    <label class="uppercase text-sm text-gray-500">Max Property Price:
                      <button class="text-accent" @click="showInfoWithText('Property prices are based on the estimated current value, not the most recent transaction price.')">
                        <icon name="information" class="cursor-pointer inline leading-none w-4 h-4" />
                      </button>
                    </label>
                    <div class="flex">
                      <select-wrapper class="mr-4">
                        <select @change="updateSelectedCampaign()" v-model="selectedCampaign.priceFilter.to" class="outline-none">
                          <option :value="null">No maximum</option>
                          <option v-for="option in priceOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                      </select-wrapper>
                    </div>
                  </form-group>
                </div>

                <div class="flex mb-2">
                  <form-group class="mr-6">
                    <label class="uppercase text-sm text-gray-500">Quality:</label>
                    <select-wrapper >
                      <select @change="updateSelectedCampaign" v-model="selectedCampaign.stockWeight" class="outline-none">
                        <option v-for="(option, i) in stockWeights" :key="i" :value="option">{{ option }}gsm</option>
                      </select>
                    </select-wrapper>
                  </form-group>

                  <div class="flex" v-if="!selectedCampaignIsAnniversaryCard">
                    <form-group class="mr-6">
                      <label class="uppercase text-sm text-gray-500" for="seed-card">Seed Card
                        <button class="text-accent" @click="showSeedCardInfo = true"><icon name="information" class="cursor-pointer inline leading-none w-4 h-4" /></button>:</label>
                      <select-wrapper >
                        <select @change="updateSelectedCampaign" v-model="selectedCampaign.seedCard" class="outline-none" id="seed-card">
                          <option value="none">None</option>
                          <option value="campaign">One Per Campaign</option>
                          <option value="street">One Per Street</option>
                        </select>
                      </select-wrapper>
                    </form-group>
                    <modal-small class="w-1/2 h-1/2" v-if="showSeedCardInfo" @close="showSeedCardInfo = false">
                      <div class="p-4 w-full">
                        <p class="text-left mb-4">A 'seed card' is a way to check that the printed campaign has been printed and delivered. By selecting this option we print one additional card per campaign or per street/area and send it to your registered office so you can see the campaign has run and been delivered.</p>
                      </div>
                    </modal-small>
                  </div>
                </div>

                <p class="bg-gray-200 text-center rounded mr-6 p-2 mt-6" v-if="!selectedCampaign.templateId && !selectedCampaignIsAnniversaryCard">A Campaign Template must be selected before the campaign can be activated.</p>
                <p class="bg-gray-200 text-center rounded mr-6 p-2 mt-6" v-else-if="!itemTemplatesSelected">Templates must be selected for all anniversary years before the campaign can be activated.</p>
                <div v-if="Object.keys(errors.errors).length > 0">
                  <p class="text-red-400">Campaign not updated</p>
                  <p v-for="(error,index) in errors.errors" class="text-red-400" :key="index">{{error[0]}}</p>
                </div>
              </loader-container>
            </div>
             <div v-else class="text-center">
                  <p class="mb-1">Please complete this form to enable print orders from your {{systemName}} account.</p>
                  <a class="p-2 rounded bg-accent text-white block w-1/5 mx-auto" href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d5a86c41-708c-4e9c-9e42-d8ec090db369&env=na3&acct=87f0312d-cc6d-4581-8346-2312d857bb1f&v=2" target="_blank" rel="noopener noreferrer">Sign Form</a>
              </div>
          </div>

          <div v-if="selectedCampaignIsAnniversaryCard && isManagementOffice">
            <anniversary-campaign-summary v-if="!loadingLocations" :campaign="selectedCampaign" :region="currentRegion" :pricePerCard="pricePerCard"></anniversary-campaign-summary>
            <div v-else class="w-80 border-l pl-10">
              <h3 class="uppercase text-sm text-gray-500 mb-4">Campaign Summary:</h3>
              <loader class="relative" size="sm" />
            </div>
          </div>
          <div v-else class="w-112 border-l pl-10">
            <h3 class="uppercase text-sm text-gray-500 mb-4">Campaign Summary:</h3>
            <div class="flex flex-col">
              <div class="flex items-center mb-6">
                <div class="rounded-full flex items-center justify-center w-12 h-12 bg-gray-400 mr-2">
                  <icon name="detached" class="w-8 h-8 text-accent" />
                </div>
                <p>
                  <span class="text-2xl font-bold">{{ propertyCountTotal }}</span> Properties Selected
                </p>
              </div>

              <div class="flex items-center">
                <div class="rounded-full flex items-center justify-center w-12 h-12 bg-green-100 mr-2">
                  <icon name="sterling" class="w-8 h-8 text-green-600" />
                </div>
                <p>
                  <span class="text-2xl font-bold">{{ moneyFormat(pricePerCard * (propertyCountTotal + seedCardCountTotal))  }}</span> {{ overallCostText }}
                </p>
              </div>

              <div class="uppercase text-sm text-gray-500 mt-4">Cost Per Item: <span class="text-black">{{ moneyFormat(pricePerCard) }}</span></div>
              <hr class="my-4">

              <div v-if="regionFiveYearIncrease<0">
                <div class="flex items-center">
                  <icon name="attention" class="text-red-400 h-8 w-8"></icon>
                  <span class="mx-2 text-lg">Attention</span>
                </div>

                <div class="flex mt-4">
                  <span>Your campaign has {{regionFiveYearIncrease}}% DECREASE over the last 5 years.</span>
                </div>
                <span>The percentage figure used in the marketing material is for the entire region. Average prices are for the Street or Locality.</span>
              </div>

              <div class="flex mt-4">
                <div v-if="selectedCampaign.active && selectedCampaign.lastOrderTimestamp" class="uppercase text-sm text-gray-500 mb-4 mr-6"> Last Print Date: <br>
                  {{ dateFormat(selectedCampaign.lastOrderTimestamp, 'dd/MM/yyyy') }}
                </div>
                <div v-if="selectedCampaign.active && selectedCampaign.nextOrderTimestamp" class="uppercase text-sm text-gray-500 mb-4"> Next Print Date: <br>
                  {{ dateFormat(selectedCampaign.nextOrderTimestamp, 'dd/MM/yyyy') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>

    <loader-container :loading="loadingLocations" v-if="!selectedCampaignIsAnniversaryCard">
      <panel class="flex flex-col">
        <div class="w-full box-border overflow-x-scroll relative">
          <div class="flex items-center mb-8">
            <h2 class="mb-3 flex-1">Included Localities / Streets</h2>
            <div class="float-right top-1 flex-5">
              <button class="flex items-center p-2 mr-5 border-2 rounded" :class="! showPrintData ? 'text-black border-green-300 bg-gray-100' : 'text-gray-500 cursor-pointer'" v-on:click="toggleView()"
              >
                <icon name="sterling" class="w-6 h-8 mr-2" :class="! showPrintData ? 'text-green-300': 'text-gray-500'" /> Campaign Costs
              </button>
            </div>
            <div class="float-right top-1 flex-5">
              <button class="flex items-center p-2 mr-5 border-2 rounded" :class="showPrintData ? 'text-black border-green-300 bg-gray-100' : 'text-gray-600 cursor-pointer'" v-on:click="toggleView()"
              >
                <icon name="graph" class="w-6 h-8 mr-2" :class="showPrintData ? 'text-green-300': 'text-gray-500'" /> Campaign Message
              </button>
            </div>
          </div>
          <table class="w-full border-collapse">
            <tr v-if="! showPrintData" class="text-xxs text-center uppercase text-gray-600 border-l-4 border-r-4 border-transparent">
              <td class="px-2 min-w- text-left align-middle">Locality / Street</td>
              <td class="px-2 min-w-2 align-middle">&nbsp;</td>
              <td class="px-2 min-w-6 align-middle">Total No. Houses</td>
              <td class="px-2 min-w-6 align-middle">Value (Per Year)</td>
              <td class="px-2 min-w-6 align-middle">Cost Per <br /> Card / Letter</td>
              <td class="px-2 min-w-6 align-middle">Marketing Cost <br /> (Per Campaign)</td>
              <td class="px-2 min-w-6 align-middle">Marketing Cost <br /> (Per Year)</td>
              <td class="px-2 min-w-6 align-middle">Remove From Campaign</td>
            </tr>
            <tr v-else class="text-xxs text-center uppercase text-gray-600 border-l-4 border-r-4 border-transparent">
              <td class="px-2 min-w- text-left align-middle">Locality / Street</td>
              <td class="px-2 min-w-2 align-middle">&nbsp;</td>
              <td class="px-2 min-w-6 align-middle">Total No. Houses</td>
              <td class="px-2 min-w-6 align-middle">Avg. Increase <br /> (Region)</td>
              <td class="px-2 min-w-6 align-middle">Avg. Sold Price<br /> Increase</td>
              <td class="px-2 min-w-6 align-middle">Avg. Sold Price<br /> (Street)</td>
              <td class="px-2 min-w-6 align-middle">No. of Transactions</td>
              <td class="px-2 min-w-6 align-middle">Remove From Campaign</td>
            </tr>

          <tr
            v-for="(location, i) in filteredLocations"
            :key="i"
            class="text-center border-l-4 border-r-4"
            :class="[
              {
                'bg-gray-100': i % 2 === 1
              },
              regionFiveYearIncrease < 0 ? 'border-red-400': '',
              getCampaignLocationsCount(location.name) > 1 ? 'border-red-400' : ''
            ]"
          >
            <td class="px-2 py-4 min-w-1 text-left">
              <div class="flex items-center">
                <icon :name="location.is_locality ? 'locality-marker' : 'street-marker'" class="w-6 h-6 mr-1" />
                <span class="underline capitalize">{{ location.unique_name }}</span>
              </div>
            </td>
            <td class="px-2 py-4 min-w-2">
              <tool-tip v-if="location.pc_unit === ''">
                No valid postcode was returned for <span class="capitalize">{{ location.name }}</span>
              </tool-tip>
            </td>
            <td class="px-2 py-4 min-w-4">{{ location.postcode_area }}</td>
            <td class="px-2 py-4 min-w-6">
              <div class="relative flex items-center justify-center">
                <loader class="relative" size="sm" v-if="location.pc_unit !== '' && loadingPropertyCounts" />
                <span v-else>{{ getPropertyCount(location.pc_unit, location.is_locality, location.postcode_area) }}</span>
              </div>
            </td>
            <td class="px-2 py-4 min-w-6" :class="location.five_year_percentage_increase<0? 'text-red-400' : ''">
              {{showPrintData ? (location.five_year_percentage_increase ? location.five_year_percentage_increase : 0) + '%' : moneyFormat(location.value / 5) }}
            </td>
            <td class="px-2 py-4 min-w-6" :class="location.five_year_value_increase< 0? 'text-red-400' : ''">
              {{showPrintData ? moneyFormat(location.five_year_value_increase) : moneyFormat(pricePerCard) }}
            </td>
            <td class="px-2 py-4 min-w-6">
              {{showPrintData ? moneyFormat(location.five_year_sold_price) : moneyFormat(pricePerCard * getPropertyCount(location.pc_unit, location.is_locality, location.postcode_area)) }}
            </td>
            <td class="px-2 py-4 min-w-6">
              {{showPrintData ? location.five_year_sales_count : moneyFormat(pricePerCard * getPropertyCount(location.pc_unit, location.is_locality, location.postcode_area) * yearlyMultiplier) }}
            </td>
            <td class="px-2 py-4 min-w-6">
              <button :disabled="getPropertyCount(location.pc_unit, location.is_locality, location.postcode_area) === 0 ? true : false" @click="removeLocation(location)" class="w-full flex items-center justify-center focus:outline-none disabled:opacity-25">
                <icon name="close" class="w-8 h-8" />
              </button>
            </td>
          </tr>
          <tr v-if="loadingLocations === false && filteredLocations.length === 0">
            <td class="py-5" colspan="9">
              <p v-if="campaignLocations.length === 0" class="text-center mb-3">There are no streets or localities in this campaign</p>
              <p class="text-center">Go to the Street or Locality Summary views to add locations to this campaign</p>
            </td>
          </tr>
        </table>
      </div>
    </panel>
    </loader-container>
    <loader-container :loading="loadingLocations" v-else-if="isManagementOffice">
      <panel class="flex flex-col">
        <div class="w-full box-border overflow-x-scroll relative">
          <div class="flex items-center mb-8">
            <h2 class="mb-3 flex-1">Included Localities / Streets</h2>
            <div class="float-right top-1 flex-5">
              <button class="flex items-center p-2 mr-5 border-2 rounded" :class="! showPrintData ? 'text-black border-green-300 bg-gray-100' : 'text-gray-500 cursor-pointer'"
              >
                <icon name="sterling" class="w-6 h-8 mr-2 text-green-300" /> Campaign Costs
              </button>
            </div>
          </div>
          <table class="w-full border-collapse">
            <tr class="text-xxs text-center uppercase text-gray-600 border-l-4 border-r-4 border-transparent">
              <td class="px-2 min-w- text-left align-middle">Locality / Street</td>
              <td class="px-2 min-w-6 align-middle">Properties with Selected Anniversaries <br /> (This Month)</td>
              <td class="px-2 min-w-6 align-middle">Cost Per Card</td>
              <td class="px-2 min-w-6 align-middle">Marketing Cost <br /> (This Month)</td>
              <td class="px-2 min-w-6 align-middle">Remove From Campaign</td>
            </tr>
            <tr
              v-for="(location, key) in anniversaryCampaignLocations"
              :key="key"
              class="text-center border-l-4 border-r-4"
              :class="[
                {
                  'bg-gray-100': key % 2 === 1
                },
                location.count < 1 ? 'border-red-400' : ''
              ]"
            >
              <td class="px-2 py-4 min-w-1 text-left">
                <div class="flex items-center">
                  <icon :name="location.is_locality ? 'locality-marker' : 'street-marker'" class="w-6 h-6 mr-1" />
                  <span class="underline capitalize">{{ location.name }}</span>
                </div>
              </td>
              <td class="px-2 py-4 min-w-6">
                <div class="relative flex items-center justify-center">
                  <span>{{ location.count }}</span>
                </div>
              </td>
              <td class="px-2 py-4 min-w-6">
                {{ moneyFormat(pricePerCard) }}
              </td>
              <td class="px-2 py-4 min-w-6">
                {{ moneyFormat(pricePerCard * location.count) }}
              </td>
              <td class="px-2 py-4 min-w-6">
                <button @click="removeLocation(location)" class="w-full flex items-center justify-center focus:outline-none disabled:opacity-25">
                  <icon name="close" class="w-8 h-8" />
                </button>
              </td>
            </tr>
            <tr v-if="loadingLocations === false && anniversaryCampaignLocations.length === 0">
              <td class="py-5" colspan="8">
                <p class="text-center mb-3">There are no streets or localities in this campaign</p>
                <p class="text-center">Go to the Street or Locality Summary views to add locations to this campaign</p>
              </td>
            </tr>
          </table>
        </div>
      </panel>
    </loader-container>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters, mapMutations, mapState } from 'vuex'
import CampaignPicker from '@/components/CampaignPicker'
import RegionService from '@/services/RegionService'
import PostcodeService from '@/services/PostcodeService'
import MoneyUtils from '@/MoneyUtils'
import LoaderContainer from '@/components/LoaderContainer'
import Modal from '@/components/Modal'
import ModalSmall from '@/components/ModalSmall'
import MarketingTemplates from '@/components/MarketingTemplates'
import TemplatePreview from '@/components/TemplatePreview'
import ToolTip from '@/components/ToolTip'
import ErrorBag from '@/ErrorBag'
import AnniversaryCampaignSummary from '@/components/campaigns/AnniversaryCampaignSummary'

export default {
  name: 'AutomatedMarketing',

  components: {
    CampaignPicker,
    LoaderContainer,
    Modal,
    ModalSmall,
    MarketingTemplates,
    TemplatePreview,
    ToolTip,
    AnniversaryCampaignSummary
  },

  data () {
    return {
      showTemplates: false,
      showAnniversaryTemplates: false,
      showDecreaseTemplates: false,
      showOverPrice: false,
      showUnknownCtbInfo: false,
      showSeedCardInfo: false,
      showTextInfo: false,
      textInfo: null,
      selectedCampaign: null,
      updatingCampaign: false,
      campaigns: [],
      streetPropertyCounts: {},
      localityPropertyCounts: {},
      unknownCtbStreetPropertyCounts: {},
      unknownCtbLocalityPropertyCounts: {},
      propertyCountTotal: 0,
      unknownCtbPropertyCount: 0,
      loadingPropertyCounts: true,
      loadingLocations: true,
      campaignLocations: [],
      anniversaryCampaignLocations: [],
      regionFilter: null,
      regionFiveYearIncrease: [],
      decreaseWarningMessage: '',
      pricePerCard: 0,
      showPrintData: false,
      stockWeights: [],
      categories: [],
      showDeleteConfirmFlag: false,
      showCampaignActivateDialogue: false,
      ActvivateDialogueFlags:{
        GivenActiveFlag: false,
        ImmediatelyPrintFlag: false,
        ignoreNextPrintDate: false
      },
      errors: new ErrorBag(),
      campaignLocationsCount : [],
      campaignLocationsTotalCount: 0,
      anniversaryTypes: [],
      editCampaignName: false,
      updatingCampaignName: false,
      tableKeyCount: 0
    }
  },

  computed: {
    ...mapGetters(['currentRegion']),
    ...mapState(['propertyTypeFilter', 'profile', 'system', 'accountRegions']),
    systemName() {
      return this.system ? this.system.name : 'Fee Street'
    },

    districtRegions() {
      return this.accountRegions.filter(region => !region.sector)
    },

    sectorRegions() {
      return this.accountRegions.filter(region => region.sector)
    },

    filteredLocations() {
      if (! this.regionFilter) {
        return this.campaignLocations
      }

      return this.campaignLocations.filter(location => location.postcode_area === this.regionFilter)
    },

    selectedDistricts()
    {
      if (!this.selectedCampaign || !this.selectedCampaign.regions) {
        return []
      }

      return this.selectedCampaign.regions.filter(region => !region.sector).map(region => region.id)
    },
    selectedSectors()
    {
      if (!this.selectedCampaign || !this.selectedCampaign.regions) {
        return []
      }

      return this.selectedCampaign.regions.filter(region => region.sector).map(region => region.id)
    },
    seedCardCountTotal () {
      if(this.selectedCampaign.seedCard === 'campaign') {
        return 1
      } else if(this.selectedCampaign.seedCard === 'street') {
        return this.campaignLocations.length
      }else {
        return 0
      }
    },

    unknownCtbPropertyPercent () {
      if(this.propertyCountTotal === 0) {
        return 100
      }
      if (this.selectedCampaign.councilTaxFilter.includeUnknown) {
        return Math.round((this.unknownCtbPropertyCount / this.propertyCountTotal) * 100)
      }
      return Math.round((this.unknownCtbPropertyCount / (this.propertyCountTotal + this.unknownCtbPropertyCount)) * 100)
    },

    overallCostText () {
      switch (this.selectedCampaign.frequency) {
        default:
        case 1:
          return 'per annum';
        case 2:
          return 'per quarter';
        case 3:
          return 'per bi-monthly';
        case 4:
          return 'per month';
      }
    },

    yearlyMultiplier () {
      switch (this.selectedCampaign.frequency) {
        default:
        case 1:
          return 1; // times by 1 months (whole year)
        case 2:
          return 4; // times by 4 months (quarter)
        case 3:
          return 6; // times by 6 (bi-monthly, every 2 months)
        case 4:
          return 12;
      }
    },

    canActivateCampaign () {
      return this.profile.office.other?.enable_feestreet_campaigns ? true : false
    },
    isManagementOffice () {
      return this.profile.client?.management ? true : false
    },
    priceOptions() {
      let output = []
      let minimum = 200000
      let price = null
      while(minimum < 400000) {
        price = new Intl.NumberFormat('en-UK', {
          style: 'currency',
          currency: 'GBP',
          maximumFractionDigits: 0
        }).format(minimum);
        output.push({
          label: `${price}`,
          value: minimum
        })
        minimum = minimum + 50000
      }
      while(minimum < 1000000) {
        price = new Intl.NumberFormat('en-UK', {
          style: 'currency',
          currency: 'GBP',
          maximumFractionDigits: 0
        }).format(minimum);
        output.push({
          label: `${price}`,
          value: minimum
        })
        minimum = minimum + 100000
      }
      while(minimum < 1500000) {
        price = new Intl.NumberFormat('en-UK', {
          style: 'currency',
          currency: 'GBP',
          maximumFractionDigits: 0
        }).format(minimum);
        output.push({
          label: `${price}`,
          value: minimum
        })
        minimum = minimum + 250000
      }

      while(minimum <= 2500000) {
        price = new Intl.NumberFormat('en-UK', {
          style: 'currency',
          currency: 'GBP',
          maximumFractionDigits: 0
        }).format(minimum);
        output.push({
          label: `${price}`,
          value: minimum
        })
        minimum = minimum + 500000
      }

      return output
    },

    cTaxFromOptions() {
      const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
      let output = []

      for(let i = 0; i < letters.length; i++) {
        if(letters[i] === this.selectedCampaign.councilTaxFilter.to) {
          break
        }

        output.push({
          label: `From: ${letters[i]}`,
          value: letters[i]
        })
      }

      return output
    },

    cTaxToOptions() {
      const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
      let output = []

      for(let i = letters.length - 1; i > 0; i--) {
        if(letters[i] === this.selectedCampaign.councilTaxFilter.from) {
          break
        }

        output.unshift({
          label: `To: ${letters[i]}`,
          value: letters[i]
        })
      }

      return output
    },

    councilTaxBand() {
      if (this.includeAllCtb) {
        return []
      }

      const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

      let bands = letters.slice(
        letters.indexOf(this.selectedCampaign.councilTaxFilter.from),
        letters.indexOf(this.selectedCampaign.councilTaxFilter.to) + 1
      )

      if (this.selectedCampaign.councilTaxFilter.includeUnknown === true) {
        bands.push('')
      }

      return bands
    },

    includeAllCtb() {
      return this.selectedCampaign.councilTaxFilter.from === 'A'
        && this.selectedCampaign.councilTaxFilter.to === 'H'
    },

    tableKey() {
      return 'table-' + this.tableKeyCount
    },
    anniversaryItems() {
      if(this.selectedCampaignIsAnniversaryCard) {
        return []
      }
      let items = []
      for (let index in this.anniversaryTypes) {
        items[index] = this.selectedCampaign.anniversaryItems.some(item => item.type_id === index);
      }
      return items
    },
    itemTemplatesSelected() {
      if(this.selectedCampaignIsAnniversaryCard && this.selectedCampaign.anniversaryItems.length > 0) {
        return this.selectedCampaign.anniversaryItems.every(item => item.template_id !== null && item.templated_id !== '');
      }
      return true
    },
    selectedCampaignIsAnniversaryCard () {
      if (!this.selectedCampaign) {
        return false
      }
      return this.selectedCampaign.anniversaryFlag
    }
  },

  mounted () {
    this.getCampaigns()
    RegionService.getAnniversaryTypes()
      .then(({ data }) => {
        this.anniversaryTypes = data
      })
  },

  methods: {
    ...mapMutations(['setToastMessage']),

    moneyFormat: MoneyUtils.format,
    dateFormat: (timestamp, format) => {
      return DateTime.fromSeconds(timestamp).toFormat(format)
    },

    updateCampaign (campaign) {
      for (let i = 0; i < this.campaigns.length; i++) {
        if (this.campaigns[i].id === campaign.id) {
          this.campaigns[i] = null
          this.$set(this.campaigns, i, campaign)
          this.selectedCampaign = null
          this.selectedCampaign = campaign
          this.getCampaignLocations(campaign.id)
          this.showOverPrice = campaign.overLimit
          break;
        }
      }
    },

    openRenameCampaign () {
      this.editCampaignName = true
      this.$nextTick(() => {
        document.getElementById(`campaign_name_${this.selectedCampaign.id}`).focus()
      })
    },

    updateCampaignName (campaign, name) {
      this.updatingCampaignName = true
      RegionService.updateCampaign({
        ...campaign,
        name
      }).then(() => {
        this.editCampaignName = false
        this.updatingCampaignName = false
      })
    },

    createNewCampaign (anniversaryFlag) {
      RegionService.createCampaign(this.currentRegion.id, anniversaryFlag).then(() => {
        RegionService.getCampaigns(this.currentRegion.id)
        .then(({ data }) => {
          this.selectedCampaign = data.data[data.data.length - 1] || null
          this.campaigns = data.data
        })
      })
    },

    getCampaigns () {
      RegionService.getCampaigns(this.currentRegion === 'combined' ? null : this.currentRegion.id)
        .then(({ data }) => {
          this.selectedCampaign = data.data[0] || null
          this.campaigns = data.data
          this.pricePerCard = data.campaignPricePerCard
          this.stockWeights = this.getWeights(data.stockWeights)
          this.categories = data.categories
        })
    },

    changeCampaignRegion(regionId) {
      let index = this.selectedCampaign.regions.findIndex(region => region.id === regionId)
      if (index === -1) {
        let fullRegionData = this.accountRegions.find(region => region.id === regionId)
        this.selectedCampaign.regions.push(fullRegionData)
      } else {
        this.selectedCampaign.regions.splice(index, 1)
      }

      this.updateSelectedCampaign()
    },

    getWeights (weights) {
      if (Array.isArray(weights)) {
        for (let i = 0; i < weights.length; i++) {
          if (weights[i] === 350) {
            weights.splice(i, 1)
          }
        }
      }
      return weights
    },

    getCampaignLocations (campaignId) {
      this.loadingLocations = true
      RegionService.getCampaignLocations(campaignId)
        .then(({ data }) => {
          this.campaignLocations = data
          //get region_percentage_increase
          if (data[0]) {
            this.regionFiveYearIncrease = data[0] ? data[0].region_percentage_increase : null
          }
        })
        .finally(() => {
          this.loadingLocations = false
          this.getPropertyCounts()
        })
    },

    getAnniversaryCampaignLocations (campaignId) {
      this.loadingLocations = true
      RegionService.getAnniversaryCampaignLocations(this.currentRegion.id, campaignId).then(({ data }) => {
        this.anniversaryCampaignLocations = data
      })
        .finally(() => {
          this.loadingLocations = false
        })
    },

    removeLocation (location) {
      this.loadingLocations = true
      RegionService.removeCampaignLocation(
        this.selectedCampaign.id,
        location.pc_district,
        location.pc_sector,
        location.name,
        this.getPropertyCount(location.pc_unit, location.is_locality, location.postcode_area),
        location.locality
      ).then(() => {
        if(this.selectedCampaignIsAnniversaryCard) {
          RegionService.getAnniversaryCampaignLocations(this.currentRegion.id, this.selectedCampaign.id)
            .then(({ data }) => {
              this.anniversaryCampaignLocations = data
              this.loadingLocations = false
            })
        } else {
          RegionService.getCampaignLocations(this.selectedCampaign.id)
            .then(({ data }) => {
              if (location.is_locality) {
                this.localityPropertyCounts[location.postcode_area][location.pc_unit] = 0
                this.unknownCtbLocalityPropertyCounts[location.postcode_area][location.pc_unit] = 0
              } else {
                this.streetPropertyCounts[location.postcode_area][location.pc_unit] = 0
                this.unknownCtbStreetPropertyCounts[location.postcode_area][location.pc_unit] = 0
              }
              this.setPropertyCountTotal()
              this.setUnknownCtbPropertyCount()
              this.campaignLocations = data
              this.tableKeyCount++
            })
        }
      })
    },

    getPropertyCounts() {
      this.resetCouncilTaxFilter()
      this.loadingPropertyCounts = true
      this.streetPropertyCounts = {}
      this.localityPropertyCounts = {}
      this.unknownCtbStreetPropertyCounts = {}
      this.unknownCtbLocalityPropertyCounts = {}

      let streetLocations = {}
      this.campaignLocations
        .filter(location => !location.is_locality)
        .forEach(street => {
          if (streetLocations[street.postcode_area] === undefined) {
            streetLocations[street.postcode_area] = {
              postcodes: [],
              isSector: street.area_type === 'sector'
            }
          }
          streetLocations[street.postcode_area].postcodes.push(street.pc_unit)
        })

      let localityLocations = {}
      this.campaignLocations
        .filter(location => location.is_locality)
        .forEach(locality => {
          if (localityLocations[locality.postcode_area] === undefined) {
            localityLocations[locality.postcode_area] = {
              postcodes: [],
              isSector: locality.area_type === 'sector'
            }
          }
          localityLocations[locality.postcode_area].postcodes.push(locality.pc_unit)
        })

      var promises = [];

      for (var streetPostcodeArea in streetLocations) {
          let area = streetPostcodeArea
          var postcodesArray = streetLocations[streetPostcodeArea].postcodes;
          var postcodeSector = streetLocations[streetPostcodeArea].isSector ? streetPostcodeArea : null
          promises.push(
            PostcodeService.propertyCounts(postcodesArray, 'street', postcodeSector, this.councilTaxBand, 1)
            .then(({ data }) => {
              this.streetPropertyCounts[area] = data.counts
              this.unknownCtbStreetPropertyCounts[area] = data.unknownCtb
            })
          )
      }

      for (var loclityPostcodeArea in localityLocations) {
          let area = loclityPostcodeArea
          var localityPostcodesArray = localityLocations[loclityPostcodeArea].postcodes;
          var localityPostcodeSector = localityLocations[loclityPostcodeArea].isSector ? loclityPostcodeArea : null
          promises.push(
            PostcodeService.propertyCounts(localityPostcodesArray, 'locality', localityPostcodeSector, this.councilTaxBand, 1)
            .then(({ data }) => {
              this.localityPropertyCounts[area] = data.counts
              this.unknownCtbLocalityPropertyCounts[area] = data.unknownCtb
            })
          )
      }

      Promise.all(promises).then(() => {
        this.setPropertyCountTotal()
        this.setUnknownCtbPropertyCount()
        this.loadingPropertyCounts = false
        this.tableKeyCount++
      })
      this.campaignLocations.filter(location => {
        RegionService.getCampaignLocationsCount(this.selectedCampaign.id, location.name)
        .then(({ data }) => {
          this.campaignLocationsCount[location.name] = data
          this.campaignLocationsTotalCount += data
        })
      })
    },

    setPropertyCountTotal () {
      let propertyCount = 0

      this.campaignLocations
        .forEach(location => {
          if (location.is_locality && this.localityPropertyCounts[location.postcode_area]) {
            propertyCount += this.localityPropertyCounts[location.postcode_area][location.pc_unit]
          }
          if (! location.is_locality && this.streetPropertyCounts[location.postcode_area]) {
            propertyCount += this.streetPropertyCounts[location.postcode_area][location.pc_unit]
          }
        })

      this.propertyCountTotal = propertyCount
    },

    setUnknownCtbPropertyCount () {
      let unknownCtbCount = 0

      this.campaignLocations
        .forEach(location => {
          if (location.is_locality && this.unknownCtbLocalityPropertyCounts[location.postcode_area]) {
            unknownCtbCount += this.unknownCtbLocalityPropertyCounts[location.postcode_area][location.pc_unit]
          }
          if (! location.is_locality && this.unknownCtbStreetPropertyCounts[location.postcode_area]) {
            unknownCtbCount += this.unknownCtbStreetPropertyCounts[location.postcode_area][location.pc_unit]
          }
        })

      return this.unknownCtbPropertyCount = unknownCtbCount
    },

    getPrintData () {
      const streetPostcodes = this.campaignLocations
        .filter(location => !location.is_locality)
        .map(location => {
          return {
            location: location.name.toUpperCase(),
            district: location.pc_district,
            pc_district: location.pc_district,
            campaign: this.selectedCampaign.id,
            unit: location.pc_unit,
            locality: location.locality,
            unique_name: location.unque_name
          }
        })

      const localityPostcodes = this.campaignLocations
        .filter(location => location.is_locality)
        .map(location => {
          return {
            district: location.pc_district,
            pc_district: location.pc_district,
            campaign: this.selectedCampaign.id,
            location: location.name.toUpperCase(),
            unit: location.pc_unit
          }
        })

      Promise.all([
        PostcodeService.printData(streetPostcodes),
        PostcodeService.printData(localityPostcodes, 'locality')
      ]).then(([streetData, localityData]) => {
        let locations = []

        if (Array.isArray(localityData.data)) {
            locations = locations.concat(localityData.data)
        }

        if (Array.isArray(streetData.data)) {
            locations = locations.concat(streetData.data)
        }

        this.campaignLocations = locations
        this.loadingLocations = false
      })
    },

    getPropertyCount (postcode, isLocality, area) {
      if (isLocality && this.localityPropertyCounts[area]) {
        return this.localityPropertyCounts[area][postcode] || 0
      }

      if (!isLocality && this.streetPropertyCounts[area]) {
        return this.streetPropertyCounts[area][postcode] || 0
      }

      return 0
    },

    updateSelectedCampaign () {
      this.resetCouncilTaxFilter()
      this.updatingCampaign = true
      this.errors.clearAll()
      RegionService.updateCampaign(this.selectedCampaign, this.ActvivateDialogueFlags.ignoreNextPrintDate)
        .then(({ data }) => {
          if (this.selectedCampaign.active && ! data.active && data.overLimit) {
              this.showOverPrice = true;
          }
          if(this.selectedCampaignIsAnniversaryCard) {
            this.getAnniversaryCampaignLocations(this.selectedCampaign.id)
          } else {
            this.getCampaignLocations(this.selectedCampaign.id)
          }
          this.updateCampaign(data)
        }).finally(() => {
          this.updatingCampaign = false
        }).catch(({ response }) => {
          if (response.status === 422) {
            this.errors = new ErrorBag(response.data.errors)
          }
        });
    },
    resetCouncilTaxFilter() {
      if (this.includeAllCtb) {
        this.selectedCampaign.councilTaxFilter.includeUnknown = false
      }
    },

    toggleView () {
      this.showPrintData = ! this.showPrintData
      this.loadingLocations = true
      if (this.selectedCampaignIsAnniversaryCard) {
        this.getAnniversaryCampaignLocations(this.selectedCampaign.id)
      } else {
        if (this.showPrintData) {
          this.loadingLocations = true
          return this.getPrintData()
        }
        this.getCampaignLocations(this.selectedCampaign.id)
      }

    },

    confirmDelete() {
      RegionService.deleteCampaign(this.selectedCampaign).then(() => {
        RegionService.getCampaigns(this.currentRegion === 'combined' ? null : this.currentRegion.id)
          .then(({ data }) => {
            this.selectedCampaign = data.data.length > 0 ? data.data[data.data.length - 1] : null
            this.campaigns = data.data
          })
      })
      this.showDeleteConfirmFlag = false
    },

    changeCampaignStatus() {
      if(!this.canActivateCampaign) {
        alert("Campaign can not be activated.")
        return
      }else {
        //open message popup
        if(!this.selectedCampaignIsAnniversaryCard && !this.selectedCampaign.templateId){
          alert('A Campaign Template must be selected before the campaign can be activated.')
          return
        }
        if(!this.itemTemplatesSelected) {
          alert('Templates must be selected for all anniversary years before the campaign can be activated.')
          return
        }
        var today = new Date()
        if(this.ActvivateDialogueFlags.GivenActiveFlag){
          if(this.selectedCampaign.lastOrderTimestamp !== null || this.selectedCampaign.lastOrderTimestamp !== "") {
            var nextOrderDate = (this.selectedCampaign.nextOrderTimestamp !== null || this.selectedCampaign.nextOrderTimestamp !== "") ? new Date(this.selectedCampaign.nextOrderTimestamp * 1000) : null
            if(nextOrderDate === null || today >= nextOrderDate) {
              //If the Campaign has never printed, or the last print date was longer ago than the print frequency
              this.ActvivateDialogueFlags.ImmediatelyPrintFlag = true
              this.ActvivateDialogueFlags.ignoreNextPrintDate = false
            } else {
              // If the Campaign has previously printed and the last print date was not longer ago than the print frequency
              // default value
              this.ActvivateDialogueFlags.ImmediatelyPrintFlag = false
              this.ActvivateDialogueFlags.ignoreNextPrintDate = this.selectedCampaign.force_print
            }
          } else {
            //If the Campaign has never printed, or the last print date was longer ago than the print frequency
            this.ActvivateDialogueFlags.ImmediatelyPrintFlag = true
            this.ActvivateDialogueFlags.ignoreNextPrintDate = false
          }
        }else{
          this.ActvivateDialogueFlags.ignoreNextPrintDate = false
        }
        this.showCampaignActivateDialogue = true
      }
    },
    getCampaignLocationsCount (location) {
      return this.campaignLocationsCount[location] || 1
    },
    saveAnniversaryItem (typeId, value) {
      if(value) {
        this.selectedCampaign.active = false
        RegionService.saveAnniversaryItem(this.selectedCampaign.id, typeId).then(({ data }) => {
          this.selectedCampaign.anniversaryItems.push(data)
          this.selectedCampaign.anniversaryItems.sort((a,b) => a.type_id - b.type_id);
          this.getAnniversaryCampaignLocations(this.selectedCampaign.id)
        })
      } else {
        RegionService.deleteAnniversaryItem(this.selectedCampaign.id, typeId).then( () => {
          //remove the element from selected campaigns anniversaryItems
          const index = this.selectedCampaign.anniversaryItems.findIndex(obj => String(obj.type_id) === String(typeId));
          if (index !== -1) {
            this.selectedCampaign.anniversaryItems.splice(index, 1);
          }
          this.getAnniversaryCampaignLocations(this.selectedCampaign.id)
        })
      }
    },
    checkType (typeId) {
      const exists = this.selectedCampaign.anniversaryItems.some(obj => String(obj.type_id) === String(typeId))

      return exists
    },
    showInfoWithText (message) {
      this.textInfo = message
      this.showTextInfo = true
    }
  },

  watch: {
    selectedCampaign: {
      deep: true,
      handler: function (campaign, oldCampaign) {
        if (oldCampaign === null || campaign.id !== oldCampaign.id) {
          if(this.selectedCampaignIsAnniversaryCard) {
            this.getAnniversaryCampaignLocations(campaign.id)
          } else {
            this.getCampaignLocations(campaign.id)
          }
        }
        this.ActvivateDialogueFlags.GivenActiveFlag = !this.selectedCampaign.active
      }
    },

    currentRegion (newVal, oldVal) {
      if (oldVal.id !== newVal.id) {
        this.fetchMapPolygon()
      }
    },

    propertyTypeFilter () {
      this.fetchPropertySales()
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>
